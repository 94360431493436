<template>
  <div class="col-4">
    <dropdown
      label="Gebruiker toevoegen"
      :options="options"
      @update:value="addUser"
    />
</div>
  <div
    v-for="user in filteredUsers"
    :key="user.id"
    class="row mt-2 d-flex align-items-center flex-row"
  >
    <div class="col-3 d-flex align-items-center">
      <profile-picture
        :width-and-height-in-pixels="50"
        :background-image-url="user.profilePicture"
      />
      <h6 class="ms-4"> 
        <router-link
          :to="`/app-users/${user.id}`"
        >
          {{ user.userName }}
        </router-link>
      </h6>
    </div>
    <div class="col-4 d-flex align-items-center">
      <material-input
        :id="`input_${user.id}`"
        placeholder="Functie"
        :model-value="user.userFunction"
        @change="e => updateUserFunction(user, e.target.value)"
      />
    </div>

    <div class="col d-flex align-items-center justify-content-end">
      <button
        class="btn btn-sm float-end d-inline mb-0 me-2 icon-button"
        :class="user.canEdit ? 'btn-success' : 'btn-primary'"
        @click.prevent="toggleUserEditPermission(user)"
      >
        <i
          class="top-0 start-0 bottom-0 end-0 d-flex justify-content-center align-items-center material-icons text-light fs-4"
        >
          {{ getUserEditStateIconName(user) }}
        </i>
      </button>
      <button
        class="btn btn-primary btn-sm float-end d-inline mb-0 icon-button"
        @click.prevent="removeUser(user)"
      >
        <i
          class="top-0 start-0 bottom-0 end-0 d-flex justify-content-center
                  align-items-center material-icons text-light fs-4"
        >
          delete_outline
        </i>
      </button>
    </div>
  </div>
  <div class="row mt-4">
    <div 
      class="col-12"
    >
      <material-button
        v-if="showUpdate"
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        @click="submitUpdatedUsers"
      >
        Update gegevens
      </material-button>
    </div>
  </div>
</template>

<script>

import { getAll } from '@/api/providers/users';
import { addUser, removeUser, updateUser } from '@/api/providers/company';

import Dropdown from '../../UI/Dropdown.vue';
import ProfilePicture from '../../UI/ProfilePicture.vue';

import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';

import { createYesNoSweetAlert } from '../../../helpers/sweetalert';

export default {
  name: 'CompanyUsers',

    components: {
      Dropdown,
      MaterialButton,
      MaterialInput,
      ProfilePicture,
    },

  props: {
    users: { type: Array, required: true },
    showUpdate: { type: Boolean, default: true },
  },

  emits: [
    'update:users'
  ],

  data() {
    return {
      options: [],
      apiUsers: [],
      currentUsers: undefined,
    }
  },

  computed: {
    filteredUsers() {
      return this.userList.filter(c => !c.isDeleted);
    },

    userList: {
      get() { 
        return this.currentUsers !== undefined
        ? this.currentUsers
        : this.users;
      },
      set(value)
      {
        this.currentUsers = value;
      }
    }
  },

  async mounted() {
    await this.getUsers();
  },

  methods: {
    async getUsers() {
      try {
        this.apiUsers = await getAll();
        this.options = this.mapUsersToDropdownOptions(this.apiUsers);
      }
      catch { 
        this.apiUsers = [];
        this.options = [];
      }
    },

    mapUsersToDropdownOptions(users) {
      return users.map(u => {
        return {
          value: u.id,
          label: u.fullName,
          image: u.profilePicture
        };
      });
    },

    addUser(userId) {
      if (this.userList.filter(u => u.id === userId).length > 0) return;

      const user = this.apiUsers.find(u => u.id === userId);
      if (user !== undefined) {
        this.userList.push({
          id: user.id,
          userName: user.fullName,
          profilePicture: user.profilePicture,
          isNew: true,
        });
      }
    },

    removeUser(user) {
      createYesNoSweetAlert(
        `Weet u zeker dat u ${user.userName} wilt verwijderen?`,
        () => {
          let users = this.userList;

          let index = users.findIndex(u => u === user);
          if (index < 0) return;

          users[index] = { ...users[index], isDeleted: true }

          this.userList = users;
        }
      );
    },

    toggleUserEditPermission(user) {
      if (user.canEdit === false) {
        createYesNoSweetAlert(
          `Weet u zeker dat u ${user.userName} wilt toestaan dit bedrijf aan te kunnen passen?`,
          () => {
            this.toggleUserEditState(user)
          }
        );
        return;
      }

      this.toggleUserEditState(user);
    },

    toggleUserEditState(user) {
      let users = this.userList;

      let index = users.findIndex(u => u === user);
      if (index < 0) return;

      users[index] = { ...users[index], canEdit: !user.canEdit, isUpdated: true }

      this.userList = users;
    },

    updateUserFunction(user, userFunction) {
      let users = this.userList;

      let index = users.findIndex(u => u === user);
      if (index < 0) return;

      users[index] = { ...users[index], userFunction, isUpdated: true }

      this.userList = users;
    },

    async submitUpdatedUsers(companyId) {
      const filteredUsers = this.userList.filter(c => 
        c.isUpdated && 
        !c.isDeleted && 
        !c.isNew
      );
      
      let errorCount = 0;
      for (const user of filteredUsers) {
        try {
          await updateUser(companyId, user.id, user.canEdit, user.userFunction);
        }
        catch {
          errorCount++;
        }
      }

      return errorCount == 0;
    },

    async submitNewUsers(companyId) {
      const filteredUsers = this.userList.filter(c => 
        c.isNew && 
        !c.isDeleted);

      let errorCount = 0;

      for (const user of filteredUsers) {
        try {
          await addUser(companyId, user.id, user.canEdit, user.userFunction);
        }
        catch {
          errorCount++;
        }
      }

      return errorCount == 0;
    },

    async submitRemovedUsers(companyId) {
      const filteredUsers = this.userList.filter(c => 
        c.isDeleted && 
        !c.isNew);

      let errorCount = 0;
      for (const user of filteredUsers) {
        try {
          await removeUser(companyId, user.id);
        }
        catch {
          errorCount++;
        }
      }

      return errorCount == 0;
    },

    async submit(companyId) {
      const addSuccessful = await this.submitNewUsers(companyId);
      const updateSuccessful = await this.submitUpdatedUsers(companyId);
      const removalSuccessful = await this.submitRemovedUsers(companyId);

      if (addSuccessful && updateSuccessful && removalSuccessful) return;

      throw new Error("Kon niet alle aanpassingen aan bedrijven opslaan.");
    },

    getUserEditStateIconName(user) { return user.canEdit ? 'edit' : 'edit_off'; }
  }
};
</script>

<style>
.user-function-input {
  max-width: 200px;
}

.icon-button {
  height: 38px;
  width: 38px;
}
</style>