<template>
  <div class="row mt-4">
    <div class="col-4">
      <material-input
        id="company-address"
        v-model="addressHandler"
        variant="static"
        label="Adres"
        placeholder="Vul straatnaam + huisnummer in"
      />
    </div>

    <div class="col-4">
      <material-input
        id="company-zipcode"
        v-model="zipCodeHandler"
        variant="static"
        label="Postcode"
        placeholder="Vul postcode in"
      />
    </div>

    <div class="col-4">
      <material-input
        id="company-city"
        v-model="cityHandler"
        variant="static"
        label="Plaats"
        placeholder="Vul de plaats in"
      />
    </div>
  </div>

  <div class="row mt-4">
    <material-input
      id="company-country"
      v-model="countryHandler"
      variant="static"
      label="Land"
      placeholder="Vul het land in"
    />
  </div>
    
  <div class="row mt-4">
    <div 
      class="col-12"
    >   
      <div
        id="company-map-collapse"
        class="collapse"
      >
        <location-picker
          :locationSearchString="locationSearchText"
          :initialLatitude="latitude"
          :initialLongitude="longitude"
          v-model:latitude="latitudeHandler"
          v-model:longitude="longitudeHandler"
          @update:current-position="emitPosition"
        />
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div 
      class="col-12"
    >
      <a
        class="btn bg-gradient-dark btn-sm mb-0 mt-6" 
        data-bs-toggle="collapse"
        href="#company-map-collapse" 
        aria-expanded="false" 
        aria-controls="company-map-collapse"
      >
        Zoek op kaart
      </a>   
      <material-button
        v-if="showUpdate"
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
      >
        Update gegevens
      </material-button>
    </div>
  </div>
</template>

<script>
import { createVModelHandlers } from '@/helpers/vue';

import LocationPicker from '../../UI/LocationPicker.vue';

import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';

export default {
  name: 'CompanyLocation',

  components: {
    MaterialButton,
    MaterialInput,

    LocationPicker
},

  props: {
    address: {
      type: String,
      required: true,
    },
    zipCode: {
      type: String,
      required: true,
    },
    city: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
    latitude: {
        type: Number,
        required: true
    },
    longitude: {
        type: Number,
        required: true
    },
    showUpdate: {
      type: Boolean,
      default: true,
    },
  },

  emits: [
    'update:address',
    'update:zipCode',
    'update:city',
    'update:country',
    'update:latitude',
    'update:longitude'
  ],
  
  computed: {
    ...createVModelHandlers(
    'address',
    'zipCode',
    'city',
    'country',
    'latitude',
    'longitude'
    ),

    locationSearchText() {
      let searchText = this.address ?? "";

      if (this.zipCode !== "") 
        searchText += `, ${(this.zipCode ?? '').replace(/\s/g, '')}`;

      if (this.city !== "") 
        searchText += `, ${this.city}`;

      if (this.country !== "") 
        searchText += `, ${this.country}`;

      return searchText;
    }
  },

  methods: {
    emitPosition(position) {
      this.$emit('update:latitude', position.latitude);
      this.$emit('update:longitude', position.longitude);
    }
  }
};
</script>


