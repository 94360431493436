<template>
  <Dropdown 
    label="Resultaten" 
    :options="options"
    :placeholder-label="placeholderLabel" 
    :show-images="false"
    :auto-select-if-single-option="false"
    @optionSelected="option => showPlaceOnMap(option)"
  />

  <div 
    id="map"
  />

  <div class="row mt-4">
    <div 
      class="col-6"
    >
      <material-input 
        id="company-longtitude"
        v-model="longitudeHandler"
        variant="static"
        label="Lengtegraad"
        placeholder="Vul de lengtegraad in"
      />
    </div>

    <div
      class="col-6"
    >
      <material-input 
        id="company-latitude" 
        v-model="latitudeHandler"
        variant="static" 
        label="Breedtegraad"
        placeholder="Vul de breedtegraad in" 
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import MaterialInput from '@/components/MaterialInput.vue'
import Dropdown from '@/components/UI/Dropdown.vue'

import { Loader } from "@googlemaps/js-api-loader"

// Marker array is defined outside of the component to keep Vue Proxy Handlers
// from interfering with marker management.
const markers = [];

export default {
  name: 'LocationPicker',

  components: {
    Dropdown,
    MaterialInput
  },

  props: {
    locationSearchString: { type: String, default: '' },
    
    initialLongitude: { type: Number, required: true },
    initialLatitude: { type: Number, required: true }
  },

  emits: [
    'update:latitude',
    'update:longitude'
  ],

  data() {
    return {
      googleMapsApi: {
        maps: undefined,
        place: undefined,
        autocomplete: undefined,
      },
      lastSearchText: '',
      options: [],

      longitude: undefined,
      latitude: undefined
    }
  },

  computed: {
    ...mapGetters('account', [
      'googleMapsApiKey'
    ]),

    placeholderLabel() {
      const optionsAmount = this.options.length;
      return `Doorzoek ${optionsAmount} ${optionsAmount !== 1 ? 'resultaten' : 'resultaat'}`;
    },

    longitudeHandler: {
      get() { return this.longitude ?? this.initialLongitude; },
      set(value) { 
        this.longitude = value;
        this.$emit('update:longitude', value);
      }
    },
    latitudeHandler: {
      get() { return this.latitude ?? this.initialLatitude; },
      set(value) {
        this.latitude = value;
        this.$emit('update:latitude', value);
      }
    }
  },

  watch: {
    initialLatitude(newLatitude) {
      this.initializeMarker(newLatitude, this.initialLongitude);
    },
    initialLongitude(newLongitude) {
      this.initializeMarker(this.initialLatitude, newLongitude);
    }
  },

  async mounted() {
    const loader = new Loader({
      apiKey: this.googleMapsApiKey,
      version: "weekly",
    });

    await this.initializeMap(loader);
  },

  updated() {
    if (this.updateIsRedundant()) return;

    this.updateAddressPredictions();
  },

  methods: {
    async initializeMap(loader) {
      loader.load().then(async google => {
        const { Map } = await google.maps.importLibrary('maps');
        const { AutocompleteService, PlacesService } = await google.maps.importLibrary('places');
        const { Marker } = await google.maps.importLibrary('marker');

        // Accessing the current element in this method returns a pseudo-element
        // which lacks the .querySelector method. So we use the parentNode instead.
        const parentNode = this.$el.parentNode;

        this.googleMapsApi.maps = new Map(
          parentNode.querySelector('#map'),
          { center: { lat: 52.23, lng: 4.55 }, zoom: 8 }
        );

        this.googleMapsApi.maps.addListener('click', e => {
          this.updateMapMarkerPosition('',  {
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
          });
        });

        this.googleMapsApi.marker = Marker;
        this.googleMapsApi.autocomplete = new AutocompleteService();
        this.googleMapsApi.place = new PlacesService(this.googleMapsApi.maps);

        this.updateAddressPredictions();
      });
    },

    async getPlaceFromApi(placeId) {
      return new Promise((resolve, reject) => {
        this.googleMapsApi.place.getDetails({
          field: ['geometry'],
          placeId
        }, async (place) => {
          if (place == undefined) reject(undefined);

          resolve({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          });
        });
      })
    },

    getPredictionOptionData(option) {
      return {
        label: option.description,
        value: option.place_id
      };
    },

    removeCurrentMapMarkers() {
      markers.forEach(m => m.setMap(null));
    },

    initializeMarker(latitude, longitude) {
      if (latitude && longitude) {        
        this.updateMapMarkerPosition('',  {
          lat: this.initialLatitude,
          lng: this.initialLongitude
        });
      }
    },

    apiGetPredictionsCallback(apiOptions) {
      if (apiOptions === null) { return; }

      let locations = [];

      apiOptions.forEach(p => {
        locations.push(this.getPredictionOptionData(p));
      });

      this.options = locations;
      this.lastSearchText = this.locationSearchString;
    },

    async updateAddressPredictions() {
      if (this.googleMapsApi.autocomplete != undefined) {
        this.predictedLocationOptions = [];

        this.googleMapsApi.autocomplete.getPlacePredictions(
          { input: this.locationSearchString },
          this.apiGetPredictionsCallback
        );
      }
    },

    async showPlaceOnMap(option) {
      const position = await this.getPlaceFromApi(option.value);

      if (position === undefined) return;

      this.updateMapMarkerPosition(option.label, position);
    },

    updateIsRedundant() {
      return this.locationSearchString === "" || this.locationSearchString === this.lastSearchText;
    },

    updateMapMarkerPosition(label, position) {
      this.removeCurrentMapMarkers();
        
      this.longitudeHandler = position.lng;
      this.latitudeHandler = position.lat;

      if (this.googleMapsApi.maps != undefined) {
        this.googleMapsApi.maps.setCenter(position);
        this.googleMapsApi.maps.setZoom(18);

        markers.push(new this.googleMapsApi.marker({
          label,
          position,
          map: this.googleMapsApi.maps,
        }));
      }
    }
  }
}
</script>

<style scoped lang="scss">
  #map {
    height: 500px;
  }
</style>