<template>
  <div class="row mt-4">
    <material-input
      id="company-name"
      v-model="companyNameHandler"
      variant="static"
      label="Bedrijfsnaam"
      placeholder="Vul bedrijfsnaam in"
    />
  </div>

  <div class="row mt-4">
    <material-input
      id="company-phone-number"
      v-model="phoneNumberHandler"
      variant="static"
      label="Telefoonnummer"
      placeholder="Vul uw telefoonnummer in"
    />
  </div>

  <div class="row mt-4">
    <material-input
      id="company-email-address"
      v-model="emailAddressHandler"
      variant="static"
      label="E-mail adres"
      placeholder="Vul een E-mail adres in"
    />
  </div>

  <div class="row mt-4">
    <material-input
      id="company-website"
      v-model="websiteHandler"
      variant="static"
      label="Website url"
      placeholder="Vul de domeinnaam in (incl: https://)"
    />
  </div>

  <div class="row mt-4">
    <material-textarea
      id="company-description"
      v-model="descriptionHandler"
      variant="static"
      label="Beschrijving"
      placeholder="Vul een beschrijving in"
    />
  </div>

  <div class="row mt-4">
    <material-input
      id="company-short-description"
      v-model="shortDescriptionHandler"
      variant="static"
      label="Korte beschrijving"
      placeholder="Vul een korte beschrijving in"
    />
  </div>

  <material-button
    v-if="showUpdate"
    class="float-end mt-6 mb-0"
    color="dark"
    variant="gradient"
    size="sm"
  >
    Update gegevens
  </material-button>
</template>

<script>
import { createVModelHandlers } from '@/helpers/vue';
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';
import MaterialTextarea from '@/components/MaterialTextarea.vue';

export default {
  name: 'CompanyInfo',

  components: {
    MaterialButton,
    MaterialInput,
    MaterialTextarea
  },

  props: {
    companyName: {
      type: String,
      required: true,
    },
    website: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    shortDescription: {
      type: String,
      required: true
    },
    phoneNumber: {
      type: String,
      required: true
    },
    emailAddress: {
      type: String,
      required: true
    },
    showUpdate: {
      type: Boolean,
      default: true,
    },
  },

  emits: [
    'update:companyName',
    'update:website',
    'update:description',
    'update:shortDescription',
    'update:emailAddress',
    'update:phoneNumber',
  ],

  computed: {
    ...createVModelHandlers(
    'companyName',
    'website',
    'description',
    'shortDescription',
    'emailAddress',
    'phoneNumber',
    ),
  },
};
</script>
